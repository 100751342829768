<template>
    <div></div>
</template>

<script lang="ts" setup>
const Route = useRoute()
const { buildHeaders, baseUrl, endpoints } = useApiClient()
const { $lang } = useNuxtApp()

const id = Route.params.id
const type = Route.query.type

try {
    if (id.includes('-direct-')) {
        const newurl = (id as string).replace('-direct-', '')
        await navigateTo(newurl, {
            redirectCode: 302,
        })
    }

    let redirectUrl = ''

    if (type === 'marca') {
        redirectUrl = await $fetch<string>(
            endpoints.general.shortLink.store.replace('_SLUG_', id as string),
            {
                baseURL: baseUrl,
                headers: buildHeaders(),
                method: 'GET',
            },
        )
    } else if (type === 'descuento') {
        redirectUrl = await $fetch<string>(
            endpoints.general.shortLink.discount.replace('_ID_', id as string),
            {
                baseURL: baseUrl,
                headers: buildHeaders(),
                method: 'GET',
            },
        )
    } else {
        throw createError({
            statusCode: 400,
            message: 'Error de solicitud',
        })
    }

    await navigateTo(redirectUrl, {
        redirectCode: 302,
        open: {
            target: '_self',
        },
        external: true,
    })
} catch (error) {
    console.log(`Error in redirection [tipo=${type}]: `, { error })

    throw createError({
        statusCode: 500,
        message: $lang.views.discountMask.unexpected_error || 'Error inesperado al procesar la redirección',
    })
}
</script>
